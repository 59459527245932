$(document).ready(function() {
  $(window).scroll(function() {
    if ($(document).scrollTop() > 50) {
      $('header, .navbar-toggle, .mobileDial').addClass('shrink');
    } else {
      $('header, .navbar-toggle, .mobileDial').removeClass('shrink');
    }
  });

});
